import dynamic from 'next/dynamic'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Image from 'next/image'
import Head from 'next/head'

const GoodsGroup = dynamic(() => import('@components/goods_group'))
const ParallelItems = dynamic(() => import('@components/parallel_items'))
const Layout = dynamic(() => import('@components/layouts'))
const HomeSlider = dynamic(() => import('@components/common/slider/home_slider'))

import { clx } from '@utils'
import { setMainSlides, setSpecialsList, setPopularList, setNewGoodsList } from '@redux/actions'
import { DEFAULT_SHOP_ID } from '@static/constants'
import s from './home.module.scss'


export default function HomePage({ banners, specials, seasonalGoods, popular, newGoods, isMobile }) {

   const dispatch = useDispatch()
   const mainSlides = useSelector(state => state.mainSlides)
   const choosenShopId = useSelector(state => state.choosenShopId)
   useEffect(() => {
      !banners.error && dispatch(setMainSlides(banners))
      !specials.error && dispatch(setSpecialsList(specials))
      !popular.error && dispatch(setPopularList(popular.resultGoods))
      !newGoods.error && dispatch(setNewGoodsList(newGoods.resultGoods))
   }, [])

   const slides = mainSlides || banners || []

   const metaData = {
      title: 'TA-DA! - купити товари для дому в Україні',
      heading: 'Інтернет-магазин товарів для дому',
      description: 'Інтернет-магазин товарів для дому ⭐️ Купити товари для дому за приємною ціною ☑️ Висока якість товарів ✔️ Швидка доставка в найближчий супермаркет в Україні 🚚 Телефонуйте за номером ☎️ +380 800 759 119 | TA-DA!'
   }

   return (
      <Layout disablePadding>
         <Head>
            <title>{metaData.title}</title>
            <meta name="description" content={ metaData.description } />
         </Head>
         <div className={ s.home_container }>
            <HomeSlider
               banners={ slides }
               isMobile={ isMobile }
               hidden={ isMobile }
            />
            <div className={ clx('container-xl', s.container) }>
               <HomeSlider
                  banners={ slides }
                  isMobile={ isMobile }
                  hidden={ !isMobile }
               />
               <GoodsGroup
                  title="Перекушені ціни"
                  items={ specials.error ?? specials }
                  showAll="/products/specials"
                  allGoodsText="Усі акційні товари"
                  segmentation
               />
               <GoodsGroup
                  title="Нові товари"
                  items={ newGoods.error ?? newGoods.resultGoods }
                  showAll="/products/new"
                  allGoodsText="Усі нові товари"
               />
               {choosenShopId !== DEFAULT_SHOP_ID && (
                  <GoodsGroup
                     title="Сезонні товари"
                     items={ seasonalGoods.error ?? seasonalGoods }
                     showAll="/products/seasonal"
                     allGoodsText="Усі сезонні товари"
                  />
               )}
               <ParallelItems
                  left={ (
                     <a href="https://l.ta-da.ua/app_from_site2" rel="noreferrer nofollow">
                        <Image
                           src="https://l.ta-da.ua/site_banner2"
                           width={ 691 }
                           height={ 397 }
                           alt=""
                        />
                     </a>
                  ) }
                  right={ (
                     <a href="https://l.ta-da.ua/app_from_site3" rel="noreferrer nofollow">
                        <Image
                           src="https://l.ta-da.ua/site_banner3"
                           width={ 691 }
                           height={ 397 }
                           alt=""
                        />
                     </a>
                  ) }
               />
               <GoodsGroup
                  title="Зараз популярні"
                  items={ popular.error ?? popular.resultGoods }
                  showAll="/products/popular"
               />
               <ParallelItems
                  left={ (
                     <a href="https://l.ta-da.ua/app_from_site4" rel="noreferrer nofollow">
                        <Image
                           src={ 'https://l.ta-da.ua/site_banner4' }
                           width={ 691 }
                           height={ 397 }
                           alt=""
                        />
                     </a>
                  ) }
                  right={ (
                     <a href="https://l.ta-da.ua/app_from_site5" rel="noreferrer nofollow">
                        <Image
                           src="https://l.ta-da.ua/site_banner5"
                           width={ 691 }
                           height={ 397 }
                           alt=""
                        />
                     </a>
                  ) }
               />
            </div>
         </div>
      </Layout>
   )
}
